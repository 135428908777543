// Buttons

//Card
.card {
  padding: 16px 36px;
  border-top: 1px solid $primary;
}
.card-header-title, h1, h4 {
  font-family: var(--antonio-font);
}
.cart-price-container{
  font-family: var(--antonio-font);
  font-size: 16px;
  span{
    display: block;
  }
  .price-total{
    font-size: 26px;
  }
}
.details-price-container{
  font-size: 15px;
  span{
    display: block;
  }
  .price-total{
    font-size: 22px;
  }
}
.card-header-title {
  font-size: 20px;
}

.card-header, .card-body {
  padding-left: 0;
  padding-right: 0;
  min-height: 80px;
}

//ModalCard
.modal-card {
  .card-body {
    overflow-y: inherit;
  }
}

.bg-auth {
  background-color: transparent !important;
}

//gaygechart css
.chart__gauge__wrapper {
  .chart__gauge__wrapper-pie {
    position: relative;
    width: 100%;

    div, svg {
      width: 100% !important;
      margin: 0 auto;
    }

    .chart__gauge__value-label {
      margin: 0;
      position: absolute;
      top: calc(50% + 40px);
      text-align: center;
      width: 100%;
      font-size: 26px;
    }
  }
}

.delete-item-line-icon {
  @include media-breakpoint-down(sm) {
    order: -1 !important;
  }
}

.addressContainer {
  position: relative;

  .predictions {
    height: auto;
    max-height: 200px;
    overflow: auto;
    display: flex;
    width: 100%;
    flex-direction: column;
    background: $input-bg;
    position: absolute;
    top: 70px;

    .prediction {
      margin: 8px 12px;
      cursor: pointer;
    }
  }
}

//React Select

.selectReact {
  &__control {
    background-color: $input-bg !important;
    border-color: $input-bg !important;
    color: $white !important;
    box-shadow: none !important;
    &--is-focused {
      border-color: $v-primary-one !important;
      box-shadow: $v-primary-one !important;
    }
  }
  &__placeholder {
    color: $text-muted !important;
  }
  &__menu {
    background-color: $input-bg !important;
  }
  &__input {
    color: $white !important;
  }
  &__option {
    background-color: transparent;
    &:hover {
      background-color: $primary !important;
    }
    &--is-selected {
      background-color: $primary !important;
    }
  }
  &__single-value {
    color: $input-color !important;
  }
}
.selectReactMulti {
  &__control {
    background-color: $input-bg !important;
    border-color: $input-bg !important;
    color: $black !important;
    box-shadow: none !important;
    &--is-focused {
      border-color: $v-primary-one !important;
      box-shadow: $v-primary-one !important;
    }
  }
  &__placeholder {
    color: $text-muted !important;
  }
  &__menu {
    background-color: $input-bg !important;
  }

  &__input {
    color: $white !important;
  }

  &__option {
    background-color: transparent;

    &:hover {
      background-color: $primary !important;
    }

    &--is-selected {
      background-color: $primary !important;
    }
  }

  &__multi-value {
    color: $input-color !important;
    background-color: $white !important;
    &__remove{
      background-color: $v-primary-one !important;
      color: $v-primary-one-text !important;
    }
    &__remove:hover{
      color: $v-background !important;
    } 
  }
}
.legalInformationPage{
  background: white!important;
  color: black!important;
}