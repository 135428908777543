body {
  min-height: 100%;
  background: radial-gradient(circle at 15% 10%, rgba(0, 88, 135, 1) 0%, rgba(0, 22, 28, 1) 40%);
  background-size: cover;
  background-attachment: fixed;
}

body::after {
  content: "";
  background-image: url("../../public/img/Rectangle.svg");
  background-size: auto;
  background-repeat: repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
}

.bgInstantCheckUp::before {
  content: "";
  background: radial-gradient(37% 80% at 55% 55%, rgba(35, 255, 242, 0.12) 0%, rgba(184, 255, 251, 0.00) 100%);
  position: fixed;
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -5;
}

.bgInstantCheckUp::after {
  content: "";
  background: url("../../public/img/BackgroundIstantCheckup.svg");
  position: fixed;
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -6; /* Per sovrapporre ::before sopra ::after */
}

//Accordion
.accordion-button {
  color: $black !important;
  text-transform: uppercase !important;
}

.accordion-button:not(.collapsed) {
  color: $black;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-item {
  border: none;
  &:first-child {
    .accordion-button {
      border-radius: 0 !important;
    }
  }
  &:last-child {
    .accordion-button {
      border-radius: 0 !important;
    }
  }
}

.btn-primary {
  color: $black-dark
}

.btn-secondary {
  color: $black-dark;
  background-color: $white;
}


.resizableTopLogo {
  max-width: 190px;
  max-height: 65px;
  aspect-ratio: initial;
  width: 50%;
}