//
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;
$v-background:rgba(0, 22, 28, 1);
$v-background-radial:radial-gradient(circle at 15% 10%, rgba(0, 88, 135, 1) 0%, rgba(0, 22, 28, 1) 40%);
$v-primary-one:#E4AD65;
$v-primary-one-text:#fff;
$v-primary-two:#fff;
$v-primary-two-text:#fff;
$v-secondary:#fff;
$v-secondary-text:#fff;
$v-default-text:#fff;
$v-accent:#fff;
$v-accent-text:#fff;
$v-navbar-background:#fff;
$v-navbar-text:#fff;
$v-shadow:#fff;
$v-disabled:#fff;



$white: #FFFFFF !default;
$gray-100: #F9F9F9 !default;
$gray-200: #EDEDED !default;
$gray-300: #E3E3E3 !default;
$gray-400: #D2D2D2 !default;
$gray-500: #B9B9B9 !default;
$gray-600: #A6A6A6 !default;
$gray-700: #8A8A8A !default;
$gray-800: #3F3F3F !default;
$gray-900: #282828 !default;
$black: #1A1A1A !default;

/* beautify ignore:start */
$grays: (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
) !default;
/* beautify ignore:end */

$gray-600-dark: #414141 !default;
$gray-700-dark: #3A3A3A !default;
$gray-800-dark: #132936 !default;
$gray-900-dark: #2A2A2A !default;
$black-dark: #282C3E !default;

$tooltip-bg: #3f4f64;

$btn-text-color:$black-dark;
$primary: #E4AD65 !default;
$overCustom: #926d37 !default;
$activeCustom: #665924 !default;
$input-bg-var: #2c3c49 !default;
$input-placeholder-color-var: #b8b8b8 !default;
$body-bg: rgba(0, 22, 28, 1);
$text-muted: #9699a2 !default;

// Forms
$input-bg: $input-bg-var;
$input-placeholder-color: $input-placeholder-color-var;

// Pagination
$pagination-border-color: $activeCustom!default;
$pagination-hover-bg: $overCustom !default;
$pagination-hover-border-color: $overCustom !default;
$pagination-disabled-border-color: $overCustom !default;

// Tables
$table-head-bg: $gray-800-dark !default;

// Avatar
$avatar-title-bg: $primary !default;

// Accordion
$accordion-button-bg: $primary !default;
$accordion-button-active-bg: $primary !default;
$accordion-bg: transparent !default;




