//
// user.scss
// Use this to write your custom SCSS
//
#axerve_lightBox{
    z-index: 2000;
}
@media screen and (max-height: 768px) {
    .lightBox_Container {
        height: 500px;
    }
}